/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { bignumberDependencies } from './dependenciesBignumber.generated.js';
import { fractionDependencies } from './dependenciesFraction.generated.js';
import { AccessorNodeDependencies } from './dependenciesAccessorNode.generated.js';
import { ArrayNodeDependencies } from './dependenciesArrayNode.generated.js';
import { ConstantNodeDependencies } from './dependenciesConstantNode.generated.js';
import { FunctionNodeDependencies } from './dependenciesFunctionNode.generated.js';
import { IndexNodeDependencies } from './dependenciesIndexNode.generated.js';
import { ObjectNodeDependencies } from './dependenciesObjectNode.generated.js';
import { OperatorNodeDependencies } from './dependenciesOperatorNode.generated.js';
import { SymbolNodeDependencies } from './dependenciesSymbolNode.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { parseDependencies } from './dependenciesParse.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSimplifyConstant } from '../../factoriesAny.js';
export var simplifyConstantDependencies = {
  bignumberDependencies,
  fractionDependencies,
  AccessorNodeDependencies,
  ArrayNodeDependencies,
  ConstantNodeDependencies,
  FunctionNodeDependencies,
  IndexNodeDependencies,
  ObjectNodeDependencies,
  OperatorNodeDependencies,
  SymbolNodeDependencies,
  matrixDependencies,
  parseDependencies,
  typedDependencies,
  createSimplifyConstant
};